.Showcase {
  background-color: var(--primaryColor);
  color: var(--secondaryColor);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Showcase section {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 10vh;
}

.green-name {
  color: green;
}

.red-name {
  color: red;
}

.green-name, .red-name, .draw-name{
  text-align: center;
  font-size: 1.8rem;
  width: 25%;
}

@media (max-width: 780px) {
  .Showcase section {
    width: 100%;
  }
}
