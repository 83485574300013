.Homepage {
  width: 100vw;
  height: 100vh;

  display: flex;

  background-color: var(--primaryColor);
  color: var(--secondaryColor);

  overflow: hidden;
}

.content {
  position: relative;
  z-index: 1;
}

.menu {
  font-size: 1.5rem;
  margin-top: 10vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.games-container {
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
}

.games-container button {
  font-size: 1.2rem;
  color: var(--secondaryColor);
  background-color: var(--primaryColor);

  padding: 1.2rem;
  margin: 2rem;

  border: 2px solid var(--secondaryColor);
  border-radius: 10px;

  transition: background-color 0.5s, color 0.5s ;
}

.games-container button:hover {
  background-color: var(--secondaryColor);
  color: var(--primaryColor);
}

@media (max-width:780px) {
  .games-container {
    flex-direction: column;
  }
}
