.matchmaking-wait {
  background-color: var(--primaryColor);
  color: var(--secondaryColor);

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.matchmaking-wait h1 {
  margin: 10vh;
}

.chess {
  background-color: var(--primaryColor);
  color: var(--secondaryColor);

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.chess header{
  height: 15vh;
  font-size: 2rem;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chess header h1 {
  padding: 1.2rem;
}

.chess-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.stats {
  width: 40vw;
  height: 50vh;
  overflow-y: scroll;

  color: var(--primaryColor);
  background-color: var(--secondaryColor);
  border-radius: 10px;
}

.stats ul {
  text-align: center;
}

.stats ul h1 {
  padding: 10px;
}

.stats ul li {
  font-size: 1.2rem;
  margin: 1rem;
  padding: 8px;
  list-style: none;
  background-color: gray;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.stats ul li:hover {
  transform: scale(1.05);
}

.lost-label {
  color: red;
}

.won-label {
  color: green;
}

.draw-label {
  color: darkgray;
}

.matchmaking {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-user {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  font-size: 1.5rem;
  padding: 1.2rem;
  margin: 1.2rem;

  border: 1px solid var(--secondaryColor);
  background-color: var(--primaryColor);
  color: var(--secondaryColor);
  border-radius: 5px;

  transition: background-color 0.5s, color 0.5s;
}

button:hover {
  background-color: var(--secondaryColor);
  color: var(--primaryColor);
}

@media (max-width: 780px) {
  .chess-content {
    flex-direction: column;
  }

  .stats {
    width: 80vw;
    margin-bottom: 10%;
  }

  .matchmaking {
    width: 100%;
  }
}
