:root {
  --primaryColor: #050005;
  --secondaryColor: #CCCCCC;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Times New Roman', Times, serif;
}

:root {
  background-color: var(--primaryColor);
  color: var(--secondaryColor);
}
