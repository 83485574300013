.ChessGame {
  background-color: var(--primaryColor);
  color: var(--secondaryColor);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.ChessGame section {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 15vh;
}

.clock, .runningClock{
  font-size: 1.8rem;
  text-align: center;
  padding: 1.3rem;
  width: 25%;
}

.runningClock {
  background-color: var(--secondaryColor);
  color: var(--primaryColor);
}

.name {
  text-align: center;
  font-size: 1.8rem;
  width: 25%;
}

.ChessGame label{
  display: none;
  text-align: center;
  font-size: 1.8rem;
  width: 25%;
}

.back-button {
  display: none;
  width: 25%;
}

.won-label {
  color: green;
}

.lost-label{
  color: red;
}

@media (max-width: 780px) {
  .ChessGame section {
    width: 100%;
  }
}
